import LocalizedStrings from 'react-localization';


export const strings = new LocalizedStrings({
    "en-IN": {
        name: "Name",
        age: "Age",
        years: "years",
        gender: "Gender",
        preferredLoc: "Preferred Work Location",
        education: "Education",
        interested: "Interested",
        job: "Job",
        has: "Has",
        yearsExperience: "years experience",
        bike: "Bike",
        city: "City",
        experience: "Experience",
        pan: "PAN",
        PAN: "PAN",
        aadhaar: "Aadhaar",
        'Aadhaar Front': 'Aadhaar Front',
        'Aadhaar Back': 'Aadhaar Back',
        driving: "Driving License",
        'DL Front': "DL Front",
        'DL Back': "DL Back",
        vehicleRC: "Vehicle RC",
        RC: "RC",
        bankPassbook: "Bank Passbook",
        "Bank Passbook": "Bank Passbook",
        cancelledCheque: "Cancelled Cheque",
        "Cancelled Cheque": "Cancelled Cheque",
        upload: "Upload",
        view: "View",
        bankPassbookImage: "Bank Passbook Image",
        cancelledCequeImage: "Cancelled Cheque Image",
        vehicleRCImage: "Vehicle RC Image",
        aadharImage: "Aadhaar Image",
        panImage: "PAN Image",
        drivingImage: "DL Image",
        'Resume/Biodata': 'Resume/Biodata'
    },
    "hi-IN": {
        name: "नाम",
        age: "उम्र",
        years: "साल",
        gender: "लिंग",
        preferredLoc: "पसंदीदा कार्य स्थान",
        education: "शिक्षा / शैक्षिक योग्यता",
        interested: "रुचि",
        job: "नौकरी",
        has: "",
        yearsExperience: "साल का अनुभव है",
        bike: "बाइक",
        city: "शहर",
        experience: "अनुभव",
        pan: "पैन",
        PAN: "पैन",
        aadhaar: "आधार",
        'Aadhaar Front': 'आधार आगे',
        'Aadhaar Back': 'आधार पीछे',
        'DL Front': "ड्राइविंग लाइसेंस आगे",
        'DL Back': "ड्राइविंग लाइसेंस पीछे",
        driving: "ड्राइविंग लाइसेंस",
        vehicleRC: "वाहन आर.सी.",
        RC: "वाहन आर.सी.",
        bankPassbook: "बैंक पासबुक",
        "Bank Passbook": "बैंक पासबुक",
        cancelledCheque: "रद्द किया गया चेक",
        "Cancelled Cheque": "रद्द किया गया चेक",
        upload: "अपलोड",
        view: "देखना",
        bankPassbookOrCancel: "बैंक पासबुक या रद्द चेक फ़ोटो",
        vehicleRCImage: "वाहन आर.सी.फ़ोटो",
        aadharImage: "आधार फ़ोटो",
        panImage: "पैन फ़ोटो",
        drivingImage: "ड्राइविंग लाइसेंस फ़ोटो",
        'Resume/Biodata': "बायोडाटा"
    },
    "kd-IN": {
        name: "ಹೆಸರು",
        age: "ವಯಸ್ಸು",
        years: "ವಯಸ್ಸು",
        gender: "ಲಿಂಗ",
        preferredLoc: "ಆಯ್ಕೆ ಮಾಡಿದ ಕೆಲಸದ ಸ್ಥಳ",
        education: "ಶಿಕ್ಷಣ",
        interested: "ಆಸಕ್ತಿ",
        job: "ಜಾಬ್",
        has: "",
        yearsExperience: "ವರ್ಷಗಳ ಅನುಭವ",
        bike: "ಬೈಕ್",
        city: "ನಗರ",
        experience: "ಅನುಭವ",
        pan: "ಪ್ಯಾನ್",
        PAN: "ಪ್ಯಾನ್",
        aadhaar: "ಆಧಾರ್",
        'Aadhaar Front': 'ಆಧಾರ್ ಎದುರು',
        'Aadhaar Back': 'ಆಧಾರ್ ಹಿಂದೆ',
        'DL Front': "ಡ್ರೈವಿಂಗ್ ಲೈಸೆನ್ಸ್ ಎದುರು",
        'DL Back': "ಡ್ರೈವಿಂಗ್ ಲೈಸೆನ್ಸ್ ಹಿಂದೆ",
        driving: "ಡ್ರೈವಿಂಗ್ ಲೈಸೆನ್ಸ್",
        vehicleRC: "ವಾಹನ ಆರ್ಸಿ",
        RC: "ವಾಹನ ಆರ್ಸಿ",
        bankPassbook: "ಬ್ಯಾಂಕ್ ಪಾಸ್ಬುಕ್",
        "Bank Passbook": "ಬ್ಯಾಂಕ್ ಪಾಸ್ಬುಕ್",
        cancelledCheque: "ರದ್ದುಗೊಳಿಸಿದ ಚೆಕ್",
        "Cancelled Cheque": "ರದ್ದುಗೊಳಿಸಿದ ಚೆಕ್",
        upload: "ಅಪ್ಲೋಡ್",
        view: "ನೋಟ",
        bankPassbookOrCancel: "ಬ್ಯಾಂಕ್ ಪಾಸ್ಬುಕ್ ಅಥವಾ ರದ್ದುಗೊಳಿಸಿದ ಚೆಕ್ ಫೋಟೋ",
        vehicleRCImage: "ವಾಹನ ಆರ್ಸಿ ಫೋಟೋ",
        aadharImage: "ಆಧಾರ್ ಫೋಟೋ",
        panImage: "ಪ್ಯಾನ್ ಫೋಟೋ",
        drivingImage: "ಡ್ರೈವಿಂಗ್ ಲೈಸೆನ್ಸ್ ಫೋಟೋ",
        'Resume/Biodata': 'ಬಯೋಡೇಟಾ'
    }
});