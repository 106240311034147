import React from 'react';
import Autosuggest from 'react-autosuggest';
import _ from 'lodash';

import '@material/tab-bar/dist/mdc.tab-bar.css';
import '@material/tab/dist/mdc.tab.css';
import '@material/tab-scroller/dist/mdc.tab-scroller.css';
import '@material/tab-indicator/dist/mdc.tab-indicator.css';
import '../App.css';

class MapMyIndiaPlacesAutoSuggest extends React.Component {
    constructor(props) {

        super(props);
        this.state = {
            value: '',
            suggestions: []
        };

    }

    getSuggestions = async (value) => {
        const inputValue = value.trim().toLowerCase();
        let response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/suggestLocations?text=${inputValue}`);
        return await response.json();
    };

    getSuggestionValue = suggestion => `${suggestion.placeName}, ${suggestion.placeAddress}`;

    onSuggestionsFetchRequested = ({value}) => {
        if (value && value.length > 1) {
            this.getSuggestions(value)
                .then(data => {
                    if (data.error) {
                        this.setState({
                            suggestions: []
                        });
                    } else {
                        this.setState({
                            suggestions: data.suggestedLocations
                        });
                    }
                });
        } else {
            this.setState({
                suggestions: []
            });
        }
    };

    onSuggestionsFetchRequestedDebounced = _.debounce(this.onSuggestionsFetchRequested, 500);

    onSuggestionsClearRequested = () => {
        this.setState({
            suggestions: []
        });
    };

    onChange = (event, {newValue}) => {
        this.setState({
            value: newValue
        });
    };

    render() {
        const {value, suggestions} = this.state;

        const inputProps = {
            placeholder: this.props.placeholder,
            value,
            onChange: this.onChange,
            className: "search-box"
        };

        return (
            <div className={'search-input-div'}>
                <Autosuggest
                    suggestions={suggestions}
                    onSuggestionsFetchRequested={this.onSuggestionsFetchRequestedDebounced}
                    onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                    getSuggestionValue={this.getSuggestionValue}
                    renderSuggestion={this.props.renderSuggestion}
                    inputProps={inputProps}
                    onSuggestionSelected={this.props.onSuggestionSelected}
                />
            </div>
        );
    }
}

export default MapMyIndiaPlacesAutoSuggest;
