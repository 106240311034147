import React from 'react';
import { Tab, TabBar } from '@rmwc/tabs';
import LocalizedStrings from 'react-localization';

import '@material/tab-bar/dist/mdc.tab-bar.css';
import '@material/tab/dist/mdc.tab.css';
import '@material/tab-scroller/dist/mdc.tab-scroller.css';
import '@material/tab-indicator/dist/mdc.tab-indicator.css';
import '../App.css';

let strings = new LocalizedStrings({
    "en-IN": {
        chat:"Chat",
        myJobs:"My Jobs",
        profile:"Profile",
        videos: "Learn"
    },
    "hi-IN": {
        chat:"चैट",
        myJobs:"मेरी जॉब्स",
        profile:"प्रोफाइल",
        videos: "सीखें"
    },
    "kd-IN": {
        chat:"ಚಾಟ್",
        myJobs:"ನನ್ನ ಜಾಬ್ಸ್",
        profile:"ಪ್ರೊಫೈಲ್",
        videos: "ಕಲಿ"
    }
});

class Menu extends React.Component {
    constructor(props) {

        super(props);
		this.state = {
            currentTab: 0,
            profile: '',
            jobs: '',
            videos: ''
        };

        this.tabChatSelect = this.tabChatSelect.bind(this);
        this.tabMyjobsSelect = this.tabMyjobsSelect.bind(this);
        this.tabProfileSelect = this.tabProfileSelect.bind(this);
        this.tabVideosSelect = this.tabVideosSelect.bind(this);

    }

    async tabChatSelect() {
        await this.setState({currentTab: 0});
        this.props.amplitude.getInstance().logEvent('Tab Chat');
        this.props.mixpanel.track('Tab Chat');
        await this.props.tabChange(this.state.currentTab);
    }

    async tabMyjobsSelect() {
        const userId = await localStorage.getItem('userId');

        const response = await fetch(
            `${process.env.REACT_APP_BACKEND_URL}/getJobs?userId=${encodeURIComponent(userId)}`
        );

        let userJobs = await response.json();

        await this.setState({currentTab: 1, jobs: userJobs.jobs});

        this.props.amplitude.getInstance().logEvent('Tab My Jobs');
        this.props.mixpanel.track('Tab My Jobs');
        await this.props.tabChange(this.state.currentTab);
        await this.props.appliedJobs(this.state.jobs);
    }

    async tabProfileSelect() {
        const userId = await localStorage.getItem('userId');

        const response = await fetch(
            `${process.env.REACT_APP_BACKEND_URL}/getProfile?userId=${encodeURIComponent(userId)}`
        );

        let userProfile = await response.json();

        await this.setState({currentTab: 2, profile: userProfile.profile[0]});
        if (!userProfile.profile || !userProfile.profile.length) {
			await this.setState({profile: {}});	
		} else {
			await this.setState({profile: userProfile.profile[0]});
		}

        this.props.amplitude.getInstance().logEvent('Tab Profile');
        this.props.mixpanel.track('Tab Profile');
        await this.props.tabChange(this.state.currentTab);
        await this.props.profile(this.state.profile);
    }

    async tabVideosSelect() {
        const response = await fetch(
            `${process.env.REACT_APP_BACKEND_URL}/getVideos`
        );
        let videoList = await response.json();
        await this.setState({currentTab: 3, video: videoList.videoList});
        this.props.amplitude.getInstance().logEvent('Tab Video');
        await this.props.tabChange(this.state.currentTab); // changes tab
        await this.props.videos(this.state.video); // sends video data back
    }

    render() {

        strings.setLanguage(this.props.language);

        return(
            <div>
                <TabBar className = "headerBox" activeTabIndex={this.props.currentTab}>
                    <Tab stacked icon="chat" onClick={this.tabChatSelect}>{strings.chat}</Tab>
                    <Tab stacked icon="card_travel" onClick={this.tabMyjobsSelect}>{strings.myJobs}</Tab>
                    <Tab stacked icon="account_circle" onClick={this.tabProfileSelect}>{strings.profile}</Tab>
                    <Tab stacked icon="ondemand_video" onClick={this.tabVideosSelect}>{strings.videos}</Tab>
                </TabBar>
            </div>
        );
    }
}

export default Menu;