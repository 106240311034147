import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import amplitude from 'amplitude-js/amplitude';

amplitude.getInstance().init(process.env.REACT_APP_AMPLITUDE_API_KEY);

//Todo: Remove this temp fix after all code occurances of mixpanel.track
const mixpanel = {
	track: () => {}
};

ReactDOM.render(<App mixpanel={mixpanel} amplitude={amplitude} />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.register();

window.addEventListener('load', function() {
	serviceWorker.register();
});
