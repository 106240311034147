import React, { lazy, Suspense } from 'react';
import Menu from './components/Menu';
import DocUpload from './components/FileUploader/DocumentUploader';
import CaptureLocation from './components/CaptureLocation';
import openSocket from 'socket.io-client';
import axios from 'axios';
import uuidv4 from 'uuid/v4';
import EXIF from 'exif-js';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import TopMenu from './components/TopMenu';
import ReactGA from 'react-ga';
import * as profileService from './service/profileService';

const MessageList = lazy(() => import('./components/MessageList'));
const SendMessageForm = lazy(() => import('./components/SendMessageForm'));
const MyJobs = lazy(() => import('./components/MyJobs'));
const Videos = lazy(() => import('./components/Videos'));
const Feedback = lazy(() => import('./components/Feedback'));
const Referral = lazy(() => import('./components/ReferralPage'));
const NewMyProfile = lazy(() => import('./components/NewMyProfile'));
const OfflineNotification = lazy(() => import('./components/OfflineNotification'));

class JobFinder extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			count: 0,
			userId: '',
			messages: [],
			socket: '',
			installPrompt: '',
			currentTab: this.props.currentTab ? this.props.currentTab : 0,
			currentJobs: [],
			profile: '',
			feedbackModalStatus: false,
			newBotMessage: 0,
			newUserMessage: false,
			language: 'en-IN',
			videos: ''
		};

		this.sleep = this.sleep.bind(this);
		this.addUrlParamsToMsgObj = this.addUrlParamsToMsgObj.bind(this);
		this.subscribeUser = this.subscribeUser.bind(this);
		this.syncLocalStorageWithDB = this.syncLocalStorageWithDB.bind(this);
		this.storeDBMessagesLocally = this.storeDBMessagesLocally.bind(this);
		this.markPreviousBotMessageResponded = this.markPreviousBotMessageResponded.bind(this);
		this.storeMessageLocally = this.storeMessageLocally.bind(this);
		this.renderProcessedMessages = this.renderProcessedMessages.bind(this);
		this.processMessageObjForChoices = this.processMessageObjForChoices.bind(this);
		this.processMessageObjForLocation = this.processMessageObjForLocation.bind(this);
		this.processMessageObjForPermission = this.processMessageObjForPermission.bind(this);
		this.makePhoneNumbersClickable = this.makePhoneNumbersClickable.bind(this);
		this.boldMessage = this.boldMessage.bind(this);
		this.putNewLinesInMessage = this.putNewLinesInMessage.bind(this);
		this.retreivePreviousMessages = this.retreivePreviousMessages.bind(this);
		this.addPreviousMessages = this.addPreviousMessages.bind(this);
		this.unlockUser = this.unlockUser.bind(this);
		this.lockUser = this.lockUser.bind(this);
		this.handleUserMessage = this.handleUserMessage.bind(this);
		this.handleBotMessage = this.handleBotMessage.bind(this);
		this.handleBotEvents = this.handleBotEvents.bind(this);
		this.renderSpinnerForImage = this.renderSpinnerForImage.bind(this);
		this.handleUserAttachment = this.handleUserAttachment.bind(this);
		this.uploadFile = this.uploadFile.bind(this);
		this.handleTabChange = this.handleTabChange.bind(this);
		this.handleAppliedJobs = this.handleAppliedJobs.bind(this);
		this.handleProfile = this.handleProfile.bind(this);
		this.handleVideos = this.handleVideos.bind(this);
		this.getUserFromUrl = this.getUserFromUrl.bind(this);
		this.setRef = this.setRef.bind(this);
		this.handleNotificationResponse = this.handleNotificationResponse.bind(this);
		this.getJobs = this.getJobs.bind(this);
		this.checkPercentEligibilty = this.checkPercentEligibilty.bind(this);
		this.handleFeedbackModal = this.handleFeedbackModal.bind(this);
		this.handleCloseModal = this.handleCloseModal.bind(this);
		this.processMessageObjForNewJobCard = this.processMessageObjForNewJobCard.bind(this);
		this.connectToServer = this.connectToServer.bind(this);
		this.processMessageObjForApb = this.processMessageObjForApb.bind(this);
		this.getProfile = this.getProfile.bind(this);
		this.getVideos = this.getVideos.bind(this);
		// this.appRender = this.appRender.bind(this);
		ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID);
		ReactGA.pageview(window.location.pathname);
		const connection = navigator.connection || navigator.mozConnection || navigator.webkitConnetion;
		ReactGA.event({
			category: 'Performance',
			action: 'NETWORK_STATUS',
			label: connection ? connection.effectiveType : 'NA',
			value: connection ? connection.downLink : 0,
			nonInteraction: true
		});
	}

	async componentDidMount() {
		if (this.props.currentTab === 1 && this.state.currentTab !== this.props.currentTab) {
			await this.getJobs();
			await this.setState({
				currentTab: this.props.currentTab
			});
		}

		if (this.props.currentTab === 2 && (!this.state.profile || this.state.profile === '')) {
			await this.getProfile();
		}

		if (this.props.currentTab === 3 && (!this.state.videos || this.state.videos === '')) {
			await this.getVideos();
		}

		await this.setState({
			newUserMessage: true
		});

		window.addEventListener('beforeinstallprompt', async (e) => {
			// Prevent Chrome 67 and earlier from automatically showing the prompt
			e.preventDefault();
			await this.setState({ installPrompt: e });
		});

		window.addEventListener('resize', () => {
			let vh = window.innerHeight * 0.01;
			document.documentElement.style.setProperty('--vh', `${vh}px`);
		});

		window.addEventListener('online', this.connectToServer());

		this.syncLocalStorageWithDB();
	}

	async shouldComponentUpdate() {
		if (this.props.currentTab === 1 && this.state.currentTab !== this.props.currentTab) {
			await this.getJobs();
			await this.setState({
				currentTab: this.props.currentTab
			});
		}
		if (this.props.currentTab === 2 && this.state.currentTab !== this.props.currentTab) {
			await this.getProfile();
			await this.setState({
				currentTab: this.props.currentTab
			});
		}
		if (this.props.currentTab === 3 && this.state.currentTab !== this.props.currentTab) {
			await this.getVideos();
			await this.setState({
				currentTab: this.props.currentTab
			});
		}
		return true;
	}

	async urlB64ToUint8Array(base64String) {
		const padding = '='.repeat((4 - base64String.length % 4) % 4);
		const base64 = (base64String + padding).replace(/\-/g, '+').replace(/_/g, '/');
		const rawData = atob(base64);
		const outputArray = new Uint8Array(rawData.length);
		for (let i = 0; i < rawData.length; ++i) {
			outputArray[i] = rawData.charCodeAt(i);
		}
		return outputArray;
	}

	async subscribeUser() {
		if (!('serviceWorker' in navigator) || !('PushManager' in window)) {
			this.props.amplitude.getInstance().logEvent('Push Notification UnSupported');
			this.props.mixpanel.track('Push Notification UnSupported');
			return;
		}
		const applicationServerKey = await this.urlB64ToUint8Array(`${process.env.REACT_APP_VAPID_PUBLIC_KEY}`);
		const options = { applicationServerKey, userVisibleOnly: true };
		try {
			const sw = await navigator.serviceWorker.ready;
			const subscription = await sw.pushManager.subscribe(options);
			const data = new FormData();
			data.append('notificationPermission', 'granted');
			data.append('subscription', JSON.stringify(subscription));
			data.append('userId', this.state.userId);
			let url = process.env.REACT_APP_BACKEND_URL + '/updateUserPermissions';
			await axios.post(url, data);
		} catch (error) {
			console.log(error);
		}
	}

	async sleep(ms) {
		return new Promise((resolve) => setTimeout(resolve, ms));
	}

	async addUrlParamsToMsgObj(messageObj) {
		let [url, params] = window.location.href.split('?');
		messageObj.urlParams = params;
	}

	async getUserFromUrl() {
		const phone = new URL(window.location.href).searchParams.get('phone');
		const verificationToken = new URL(window.location.href).searchParams.get('token');
		if (!phone || !verificationToken) {
			return {
				ifUserNew: true,
				userId: uuidv4()
			};
		}
		const response = await fetch(
			`${process.env.REACT_APP_BACKEND_URL}/getUser?phone=${phone}&verificationToken=${verificationToken}`
		);

		const userDetail = await response.json();
		return userDetail;
	}

	async checkPercentEligibilty(userId) {
		if (userId && userId.length > 0) {
			var sum = 0;

			for (var i = 0; i < userId.length; i++) {
				sum += userId[i].charCodeAt();
			}
			var fraction = sum % 4;
			if (fraction === 0) {
				return true;
			}
		}
		return false;
	}

	async connectToServer(userId) {
		const self = this;
		if (this.state.socket) {
			this.state.socket.destroy();
			delete this.state.socket;
		}
		await self.sleep(100);
		const socket = openSocket(process.env.REACT_APP_BACKEND_URL, {
			reconnection: true,
			reconnectionDelay: 1000,
			reconnectionDelayMax: 5000,
			reconnectionAttempts: Infinity,
			query: `uuid=${userId}`
		});
		await this.setState({ socket: socket });
		this.handleBotMessage();
		this.handleBotEvents();
		// this.handleBotMessage();
		// this.handleBotEvents();
		this.state.socket.on('connect', function () {
			console.log('connected to server');
		});
		this.state.socket.on('disconnect', async function () {
			console.log('disconnected from server');
			await self.sleep(100);
			await self.connectToServer(self.state.userId);
		});
	}

	async syncLocalStorageWithDB() {
		const jobId = new URL(window.location.href).searchParams.get('jobId');
		const state = new URL(window.location.href).searchParams.get('state');
		let ifUserNew = true;
		let userId = localStorage.getItem('userId') || 0;
		const phone = new URL(window.location.href).searchParams.get('phone') || 0;
		const verificationToken = new URL(window.location.href).searchParams.get('token') || 0;
		let locallyStoredMsgCount = Number(localStorage.getItem('messagesCount')) || 0;

        console.log('Get User Chat History Called');
		let url = `${process.env.REACT_APP_BACKEND_URL}/getUserChatHistory?
		userId=${userId}&
		phone=${phone}&
		verificationToken=${verificationToken}&
		locallyStoredMsgCount=${locallyStoredMsgCount}`;

        let userChatHistory = {ifUserNew: true, ifLocalStorageToBeCleared: true, userId: uuidv4()};
        try {
            let userChatHistoryResponse = await fetch(url);
            console.log('Get User Chat History Returned');
            userChatHistory = await userChatHistoryResponse.json();
        } catch (error) {
            console.log('Error in fetching history');
            console.log(error);
        }

		// if (userId) {
		// 	ifUserNew = false;
		// 	// const response = await fetch(
		// 	// 	`${process.env.REACT_APP_BACKEND_URL}/checkUser?userId=${userId}`
		// 	// );
		// 	// const userDetail = await response.json();
		// 	ifUserNew = userChatHistory.ifUserNew;
		// 	userId = userChatHistory.userId;
		// 	localStorage.setItem('userId', userId);
		// 	localStorage.setItem('phoneNumber', userChatHistory.phoneNumber);
		// }

		// if (ifUserNew) {
		// 	localStorage.clear();
		// 	// const userDetail = await this.getUserFromUrl();
		// 	ifUserNew = userChatHistory.ifUserNew;
		// 	userId = userChatHistory.userId;
		// 	localStorage.setItem('userId', userId);
		// 	localStorage.setItem('phoneNumber', userChatHistory.phoneNumber);
		// }

		if (userChatHistory.ifLocalStorageToBeCleared) {
			localStorage.clear();
		}
		ifUserNew = userChatHistory.ifUserNew;
		userId = userChatHistory.userId;
		localStorage.setItem('userId', userId);
		localStorage.setItem('phoneNumber', userChatHistory.phoneNumber);

		await this.connectToServer(userId);

		await this.setState({ userId: userId });

		let language = localStorage.getItem('language');
		if (language) {
			await this.setState({
				language: language
			});
		}

		const now = new Date();
		let pageSession = localStorage.getItem('pageSession');

		if (pageSession) {
			pageSession = JSON.parse(pageSession);
			if (Math.floor(now.getTime()) - pageSession.createdAt >= pageSession.ttl) {
				pageSession = {
					createdAt: now.getTime(),
					ttl: 1800000
				};
				localStorage.setItem('pageSession', JSON.stringify(pageSession));
			}
		} else {
			pageSession = {
				createdAt: now.getTime(),
				ttl: 1800000
			};
			localStorage.setItem('pageSession', JSON.stringify(pageSession));
		}

		if (ifUserNew) {
			// if (!jobId) {
			// 	localStorage.clear();
			// 	window.location = process.env.REACT_APP_OPT_IN_URL;
			// } else {
            console.log('New User Found');
			const initialMessage = new URL(window.location.href).searchParams.get('text') || 'Hi';
			const source = new URL(window.location.href).searchParams.get('source') || '';
			this.handleUserMessage({ message: initialMessage, jobId: jobId, source: source });
			// }
		} else {
            console.log('Old User Found ' + this.state.userId);
			const source = new URL(window.location.href).searchParams.get('source') || '';
			const data = new FormData();
			data.append('userId', this.state.userId);
			data.append('source', source);
            let url = process.env.REACT_APP_BACKEND_URL + '/setSource';
            console.log('User Source To Be Set');
            await axios.post(url, data);
            console.log('User Source Set');
			try {
				this.props.amplitude.getInstance().setUserId(userId);
				localStorage.setItem('amplitudeSetForUser', userId);

				if (Math.floor(now.getTime()) - pageSession.createdAt < 2000) {
					this.props.amplitude
						.getInstance()
						.logEvent('PWA Visited', { page: window.location.href, tab: this.state.currentTab });
					if (new URL(window.location.href).searchParams.get('source') === 'notification') {
						this.props.amplitude.getInstance().logEvent('Job Recommendation Notification Clicked', {
							page: window.location.href,
							tab: this.state.currentTab
						});
					}
				}

				// if (localStorage.getItem('mixpanelSetForUser') !== userId) {
				// 	this.props.mixpanel.alias(userId);
				// 	localStorage.setItem('mixpanelSetForUser', userId);
				// }

				// this.props.mixpanel.people.set({'userId': userId});
				// this.props.mixpanel.identify(userId);
				// this.props.mixpanel.register({userId: userId});

				// let locallyStoredMsgCount = Number(localStorage.getItem('messagesCount')) || 0;

				// const response = await fetch(
				// `${process.env.REACT_APP_BACKEND_URL}/getHistory?userId=${this.state.userId}&locallyStoredMsgCount=${locallyStoredMsgCount}`
				// );

				const userHistory = userChatHistory.userHistory;
				const inSync = userHistory.inSync;
				const notificationPermission = userHistory.notificationPermission;

				if (!inSync) {
					const dbMessages = userHistory.messages;
					console.log(dbMessages.length);
					console.log(locallyStoredMsgCount);
					localStorage.clear();
					localStorage.setItem('userId', userId);
					localStorage.setItem('jobId', userHistory.jobId);
					localStorage.setItem('notificationPermission', notificationPermission);
					localStorage.setItem('phoneNumber', userHistory.phoneNumber);
					localStorage.setItem('amplitudeSetForUser', userId);
					localStorage.setItem('mixpanelSetForUser', userId);
					localStorage.setItem('pageSession', JSON.stringify(pageSession));
                    console.log('Message Not in Sync');
					await this.storeDBMessagesLocally(dbMessages);
				} else {
					const previousMessages = await this.retreivePreviousMessages();
					await this.addPreviousMessages(previousMessages);
                }
                console.log('Messages added to UI');
				if (
					jobId &&
					localStorage.getItem('jobId') !== jobId &&
					localStorage.getItem('phoneNumber') &&
					localStorage.getItem('phoneNumber').length === 10
				) {
					localStorage.setItem('jobId', jobId);
					const data = new FormData();
					data.append('phoneNumber', localStorage.getItem('phoneNumber'));
					data.append('jobId', jobId);
					let url = process.env.REACT_APP_BACKEND_URL + '/storeJobInRedis';
					await axios.post(url, data);
					this.handleUserMessage({ jobId: jobId });
					return;
				}

				if (
					state &&
					state === 'Reset' &&
					localStorage.getItem('phoneNumber') &&
					localStorage.getItem('phoneNumber').length === 10
				) {
					this.handleUserMessage({ state: state });
					return;
				}
				locallyStoredMsgCount = Number(localStorage.getItem('messagesCount')) || 0;

				if (locallyStoredMsgCount === 0) {
                    console.log('No messages found for the user');
					const initialMessage = new URL(window.location.href).searchParams.get('text') || 'Hi';
					this.handleUserMessage({ message: initialMessage });
				}
			} catch (error) {
				console.log(error);
				const previousMessages = await this.retreivePreviousMessages();
				this.addPreviousMessages(previousMessages);
			}
		}
	}

	async storeDBMessagesLocally(dbMessages) {
		this.unlockUser();
		// const now = new Date(messageJson.createdAt);
		// let time;
		// let h = now.getHours();
		// let t = now.getMinutes();
		// h < 10 ? h = '0' + h : h = h;
		// t < 10 ? t = '0' + t : t = t;
		// h < 12 ? time = h + ':' + t + ' am' : time = h - 12 + ':' + t + ' pm';
		for (let i = 0; i < dbMessages.length; i += 1) {
			let responded;

			if (dbMessages[i].type === 'Outgoing' && i !== dbMessages.length - 1) {
				responded = true;
			} else if (dbMessages[i].type === 'Outgoing' && i === dbMessages.length - 1) {
				responded = false;
			}

			const messageObj = {
				key: i,
				type: dbMessages[i].type === 'Incoming' ? 'outgoing' : 'incoming',
				id: dbMessages[i].id || null,
				// time: time,
				// ts: now.getTime(),
				message: dbMessages[i].choiceText ? dbMessages[i].choiceText : dbMessages[i].message,
				responded: responded,
				mediaUrl: dbMessages[i].url,
				userId: dbMessages[i].userId
			};

			await this.storeMessageLocally(messageObj);
		}
	}

	async unlockUser() {
		await this.sleep(30000);
		const now = new Date();
		const userLocked = localStorage.getItem('userLocked');

		if (userLocked) {
			const userLockedObj = JSON.parse(userLocked);

			if (Math.floor(now.getTime()) - userLockedObj.createdAt >= userLockedObj.ttl) {
				localStorage.removeItem('userLocked');
			}
		}
	}

	async lockUser() {
		const now = new Date();

		const userLockedObj = {
			createdAt: now.getTime(),
			ttl: 60000
		};

		localStorage.setItem('userLocked', JSON.stringify(userLockedObj));
	}

	async makePhoneNumbersClickable(messageObj) {
		if (!messageObj.message) {
			return messageObj;
		}
		const phonedef = new RegExp('(?:(?:\\+|0{0,2})91(\\s*[\\- ]\\s*)?|[0 ]?)?[6789]\\d{9}|(\\d[ -]?){10}\\d', 'g');
		messageObj.message = messageObj.message.replace(phonedef, function myFunction(x) {
			return "<a href='tel:" + x.replace(/(\s|-)/g, '') + "'>" + x + '</a>';
		});
		return messageObj;
	}

	async boldMessage(messageObj) {
		if (!messageObj.message) {
			return messageObj;
		}

		const msgBlocks = messageObj.message.split('*');
		let msg = '';
		for (let i = 0; i < msgBlocks.length - 2; i += 2) {
			msgBlocks[i] = msgBlocks[i] !== 'undefined' ? msgBlocks[i] : '';
			msgBlocks[i + 1] = msgBlocks[i + 1] !== 'undefined' ? msgBlocks[i + 1] : '';
			msg += msgBlocks[i] + '<b>' + msgBlocks[i + 1] + '</b>';
		}
		msg += msgBlocks[msgBlocks.length - 1];
		messageObj.message = msg;

		return messageObj;
	}

	async putNewLinesInMessage(messageObj) {
		if (messageObj.message) {
			messageObj.message = messageObj.message.replace(/[\n\r]/g, '<br/>');
		}

		return messageObj;
	}

	async processMessageObjForChoices(messageObj) {
		messageObj.userChoices = [];
		let message = messageObj.message;

		if (!message || message.indexOf('\n\n1️⃣') === -1) {
			return messageObj;
		}

		const messageArray = message.split('\n\n1️⃣');

		if (messageArray.length < 2) {
			return messageObj;
		}

		// await this.setState({
		// 	newBotMessage: this.state.newBotMessage + 1,
		// 	newUserMessage: false
		// });

		const emojis = [
			'\n2️⃣',
			'\n3️⃣',
			'\n4️⃣',
			'\n5️⃣',
			'\n6️⃣',
			'\n7️⃣',
			'\n8️⃣',
			'\n9️⃣',
			'\n1️⃣0️⃣',
			'\n1️⃣1️⃣',
			'\n1️⃣2️⃣',
			'\n1️⃣3️⃣',
			'\n1️⃣4️⃣',
			'\n1️⃣5️⃣',
			'\n1️⃣6️⃣',
			'\n1️⃣7️⃣'
		];
		messageObj.message = messageArray[0];
		let userChoicesString = messageArray[1].split('\n\n')[0];

		for (const eachEmoji of emojis) {
			const splitingRemainingChoices = userChoicesString.split(eachEmoji);
			messageObj.userChoices.push(splitingRemainingChoices[0]);

			if (splitingRemainingChoices.length < 2) {
				return messageObj;
			}
			userChoicesString = splitingRemainingChoices[1];
		}

		return messageObj;
	}

	async processMessageObjForLocation(messageObj) {
		messageObj.isLocationType = false;
		let message = messageObj.message;

		if (!message || message.indexOf('LOCATIONSELECTION') === -1) {
			return messageObj;
		}

		message = message.replace(/LOCATIONSELECTION/g, '');
		message = message.replace(/\n/, '');
		messageObj.isLocationType = true;
		messageObj.message = message;

		return messageObj;
	}

	async processMessageObjForPermission(messageObj) {
		messageObj.isPermissionType = false;
		let message = messageObj.message;

		if (!message || message.indexOf('{{PERMISSION}}') === -1) {
			return messageObj;
		}

		message = message.replace(/{{PERMISSION}}/g, '');
		messageObj.isPermissionType = true;
		messageObj.message = message;

		return messageObj;
	}

	async markPreviousBotMessageResponded() {
		const itemCount = Number(localStorage.getItem('messagesCount')) || 0;

		if (!itemCount) {
			return;
		}

		const prevMessageObjKey = 'message_' + (itemCount - 1);
		const prevMessageObj = JSON.parse(localStorage.getItem(prevMessageObjKey));
		if (prevMessageObj.type === 'outgoing' || prevMessageObj.responded) {
			return;
		}
		let messages = this.state.messages;
		messages.pop();
		await this.setState({ messages: messages });
		prevMessageObj.responded = true;
		messages.push(prevMessageObj);
		await this.setState({ messages: messages });
		localStorage.removeItem(prevMessageObjKey);
		localStorage.setItem(prevMessageObjKey, JSON.stringify(prevMessageObj));
	}

	async getPreviousBotMessage() {
		let previousBotMsgObj = {};
		const itemCount = Number(localStorage.getItem('messagesCount')) || 0;

		for (let i = itemCount - 1; i >= 0; i--) {
			if (JSON.parse(localStorage.getItem('message_' + i)).type === 'incoming') {
				previousBotMsgObj = JSON.parse(localStorage.getItem('message_' + i));
				break;
			}
		}
		return previousBotMsgObj;
	}

	async storeMessageLocally(messageObj) {
		if (messageObj.userId) {
			localStorage.setItem('userId', messageObj.userId);
			await this.setState({ userId: messageObj.userId });
		}
		messageObj.ts = new Date().getTime();
		messageObj = await this.processMessageObjForChoices(messageObj);
		messageObj = await this.processMessageObjForLocation(messageObj);
		messageObj = await this.processMessageObjForPermission(messageObj);
		messageObj = await this.makePhoneNumbersClickable(messageObj);
		messageObj = await this.boldMessage(messageObj);
		messageObj = await this.putNewLinesInMessage(messageObj);
		messageObj = await this.processMessageObjForNewJobCard(messageObj);
		messageObj = await this.processMessageObjForApb(messageObj);

		let messages = this.state.messages;
		const newMessage = {};
		for (const key in messageObj) {
			newMessage[key] = messageObj[key];
		}
		if (!localStorage.getItem('spinner')) {
			messages.push(newMessage);
			await this.setState({ messages: messages });
			await this.sleep(50);
		} else {
			newMessage.toBeProcessed = true;
		}
		const itemCount = Number(localStorage.getItem('messagesCount')) || 0;
		localStorage.setItem('message_' + itemCount, JSON.stringify(newMessage));
		localStorage.setItem('messagesCount', itemCount + 1);

		if (newMessage.type === 'incoming' && itemCount > 5) {
			await this.setState({
				newBotMessage: this.state.newBotMessage + 1,
				newUserMessage: false
			});
		} else if (newMessage.type === 'outgoing') {
			// || (newMessage.isExpectingResponse && newMessage.isExpectingResponse === "true")
			await this.setState({
				newBotMessage: 0,
				newUserMessage: true
			});
		}
	}

	async renderProcessedMessages() {
		let messages = this.state.messages;
		let spinnerObj = messages.pop();
		if (!spinnerObj.isSpinner) {
			console.log('Spinner Not Found!!');
			messages.push(spinnerObj);
		}

		const itemCount = Number(localStorage.getItem('messagesCount')) || 0;

		let processedMessageObjs = [];
		for (let i = itemCount - 1; i >= 0; i--) {
			const messageObj = JSON.parse(localStorage.getItem('message_' + i));
			if (messageObj.toBeProcessed) {
				messageObj.toBeProcessed = null;
				localStorage.setItem('message_' + i, JSON.stringify(messageObj));
				processedMessageObjs.unshift(messageObj);
			} else {
				break;
			}
		}

		messages.push(...processedMessageObjs);
		await this.setState({ messages: messages });
	}

	async renderSpinnerForImage(width, height) {
		await this.markPreviousBotMessageResponded();
		let messages = this.state.messages;
		const spinnerObj = {
			key: messages.length,
			imageWidth: width,
			imageHeight: height,
			type: 'outgoing',
			isSpinner: true,
			imageUrl: `${process.env.PUBLIC_URL}/spinner_black.gif`
		};
		localStorage.setItem('spinner', spinnerObj);
		messages.push(spinnerObj);
		await this.setState({ messages: messages });
	}

	async retreivePreviousMessages() {
		const previousItems = [];
		const itemCount = Number(localStorage.getItem('messagesCount')) || 0;

		for (let i = 0; i < itemCount; i++) {
			previousItems.push(JSON.parse(localStorage.getItem('message_' + i)));
		}

		return previousItems;
	}

	async addPreviousMessages(previousMessages) {
		let messages = this.state.messages;

		for (let i = 0; i < previousMessages.length; i++) {
			const messageObj = previousMessages[i];
			messages.push(messageObj);
		}

		await this.setState({ messages: messages });
	}

	async handleUserMessage(messageData, mediaData, location) {
		// let time;
		// // $('.conversation-container').scrollTop() + 155065;
		// const now = new Date();
		// let h = now.getHours();
		// let t = now.getMinutes();
		// h < 10 ? h = '0' + h : h = h;
		// t < 10 ? t = '0' + t : t = t;
		// h < 12 ? time = h + ':' + t + ' am' : time = h - 12 + ':' + t + ' pm';

		if (location) {
			const data = new FormData();
			data.append('lat', location.lat);
			data.append('lng', location.lng);
			data.append('eLoc', location.eLoc);
			data.append('userId', this.state.userId);
			let url = process.env.REACT_APP_BACKEND_URL + '/updateUserLocation';
			await axios.post(url, data);
		}

		if (
			messageData.message &&
			(messageData.message.toLowerCase().includes('install') || messageData.message.toLowerCase().includes('yes'))
		) {
			const previousMsg = await this.getPreviousBotMessage();
			if (previousMsg.isPermissionType && this.state.installPrompt) {
				try {
					this.state.installPrompt.prompt();
					this.props.amplitude.getInstance().logEvent('App Install Prompt Shown');
					this.props.mixpanel.track('App Install Prompt Shown');
					let outcome = await this.state.installPrompt.userChoice;

					if (outcome.outcome === 'accepted') {
						this.props.amplitude.getInstance().logEvent('App Installed');
						this.props.mixpanel.track('App Installed');
						await this.setState({ installPrompt: '' });
					} else {
						this.props.amplitude.getInstance().logEvent('App Install Rejected');
						this.props.mixpanel.track('App Install Rejected');
					}
					const data = new FormData();
					data.append('addToHomeScreen', outcome.outcome);
					data.append('userId', this.state.userId);
					let url = process.env.REACT_APP_BACKEND_URL + '/updateUserPermissions';
					await axios.post(url, data);
				} catch (err) {
					console.log(err);
				}
			}
			if (previousMsg.isPermissionType && localStorage.getItem('notificationPermission') !== 'granted') {
				let outcome = await Notification.requestPermission();
				localStorage.setItem('notificationPermission', outcome);
				if (outcome === 'granted') {
					this.props.amplitude.getInstance().logEvent('Notification Allowed');
					this.props.mixpanel.track('Notification Allowed');
					await this.subscribeUser();
				} else {
					if (outcome === 'denied') {
						this.props.amplitude.getInstance().logEvent('Notification Blocked');
						this.props.mixpanel.track('Notification Blocked');
					} else {
						this.props.amplitude.getInstance().logEvent('Notification Permission Ignored');
						this.props.mixpanel.track('Notification Permission Ignored');
					}
					const data = new FormData();
					data.append('notificationPermission', outcome);
					data.append('userId', this.state.userId);
					let url = process.env.REACT_APP_BACKEND_URL + '/updateUserPermissions';
					await axios.post(url, data);
				}
			}
		}

		const messageObj = {
			key: this.state.messages.length,
			type: 'outgoing',
			userId: this.state.userId || null,
			// time: time,
			message: messageData ? messageData.message : null,
			imageUrl: mediaData ? mediaData.imageUrl : null,
			videoUrl: mediaData ? mediaData.videoUrl : null,
			thumbnailUrl: mediaData ? mediaData.thumbnailUrl : null,
			fileName: mediaData && mediaData.file ? mediaData.file.name : null,
			imageWidth: mediaData ? mediaData.width : null,
			imageHeight: mediaData ? mediaData.height : null,
			jobId: messageData.jobId,
			source: messageData ? messageData.source : 'PWA'
		};
		if (messageData.state) {
			messageObj.state = messageData.state;
		}
		messageObj.mediaUrl = messageObj.imageUrl || messageObj.videoUrl;
		await this.markPreviousBotMessageResponded();
		await this.storeMessageLocally(messageObj);

		const messageObjToBeSent = { ...messageObj };
		await this.addUrlParamsToMsgObj(messageObjToBeSent);
		if (messageData.message) {
			messageObjToBeSent.message = messageData.choicePicked
				? messageData.choicePicked + '->buttonLabel->' + messageData.message
				: messageData.message;
		}

		if (localStorage.getItem('userLocked')) {
			console.log('ignoring message from locked user ' + this.state.userId);
		} else {
			// this.lockUser();
			await this.setState({ count: 0 });
			let i = 0;
			for (i = 0; i < 100; i += 1) {
				if (!this.state.socket || !this.state.socket.connected) {
					this.connectToServer(this.state.userId);
					await this.sleep(1000);
					continue;
				} else {
                    console.log('Message sent to Bot');
					this.state.socket.emit('message', messageObjToBeSent);
					break;
				}
			}
			if (i === 100) {
				this.props.amplitude.getInstance().logEvent('Message Dropped');
			}
		}
	}

	async handleBotMessage() {
		const self = this;
		const DELAY_IN_MESSAGE = 350;

		this.state.socket.on('incoming-message', async function (messageObj) {
            console.log('Message received from Bot');
			messageObj.type = 'incoming';
			messageObj.responded = false;
			const itemCount = Number(localStorage.getItem('messagesCount')) || 0;

			if (itemCount) {
				await self.setState({ count: self.state.count + 1 });
			}

			if (self.state.language !== messageObj.language) {
				await self.setState({
					language: messageObj.language
				});
				localStorage.setItem('language', self.state.language);
			}

			await self.sleep(DELAY_IN_MESSAGE * (self.state.count - 1));
			messageObj.key = self.state.messages.length;

			if (localStorage.getItem('spinner')) {
				localStorage.removeItem('spinner');
				await self.renderProcessedMessages();
				await self.sleep(1000);
			}
			localStorage.removeItem('userLocked');
			localStorage.setItem('userId', messageObj.userId);
			// let time;
			// let h = now.getHours();
			// let t = now.getMinutes();
			// h < 10 ? h = '0' + h : h = h;
			// t < 10 ? t = '0' + t : t = t;
			// h < 12 ? time = h + ':' + t + ' am' : time = h - 12 + ':' + t + ' pm';
			// messageObj.time = time;

			await self.setState({ userId: messageObj.userId });
			await self.storeMessageLocally(messageObj);
			// await self.setState({
			// 		newBotMessage: self.state.newBotMessage + 1,
			// 		newUserMessage: false
			// 	});

			if (localStorage.getItem('amplitudeSetForUser') !== self.state.userId) {
				self.props.amplitude.getInstance().setUserId(self.state.userId);
				localStorage.setItem('amplitudeSetForUser', self.state.userId);
				const now = new Date();
				let pageSession = localStorage.getItem('pageSession');
				if (pageSession) {
					pageSession = JSON.parse(pageSession);
					if (Math.floor(now.getTime()) - pageSession.createdAt < 10000) {
						self.props.amplitude
							.getInstance()
							.logEvent('PWA Visited', { page: window.location.href, tab: self.state.currentTab });
					}
				}
			}

			// if (localStorage.getItem('mixpanelSetForUser') !== self.state.userId) {
			// 	self.props.mixpanel.alias(self.state.userId);
			// 	localStorage.setItem('mixpanelSetForUser', self.state.userId);
			// 	self.props.mixpanel.people.set({ 'userId': self.state.userId});
			// 	self.props.mixpanel.identify(self.state.userId);
			// 	self.props.mixpanel.register({userId: self.state.userId});
			// }

			if (messageObj.message) {
				self.props.amplitude.getInstance().logEvent('Message Received', { message: messageObj.message });
				self.props.mixpanel.track('Message Received', { message: messageObj.message });
			} else if (messageObj.imageUrl) {
				self.props.amplitude.getInstance().logEvent('Media Received', { media: messageObj.imageUrl });
				self.props.mixpanel.track('Media Received', { media: messageObj.imageUrl });
			}
			// localStorage.setItem('askPhoneNumber', true);
		});
	}

	async handleBotEvents() {
		const self = this;

		this.state.socket.on('redirect-to-wa', async function (response) {
			await self.sleep(1000);
			window.open(`https://api.whatsapp.com/send?phone=${response.whatsAppPhoneNumber}`);
		});

		this.state.socket.on('reload-page', async function (response) {
			await self.sleep(4000);
			window.location.reload();
		});

		this.state.socket.on('open-page', async function (redirectUrl) {
			await self.sleep(4000);
			localStorage.clear();
			window.location = redirectUrl;
		});

		this.state.socket.on('open-feedback', async function (redirectUrl) {
			await self.sleep(2500);
			if (localStorage.getItem('feedback')) {
				console.log('feedback had already been asked ' + this.state.userId);
				return;
			} else {
				localStorage.setItem('feedback', true);
				await self.handleFeedbackModal(true);
			}
		});

		this.state.socket.on('add-to-homescreen', async function () {
			if (self.state.installPrompt) {
				try {
					self.state.installPrompt.prompt();
					self.props.amplitude.getInstance().logEvent('App Install Prompt Shown');
					self.props.mixpanel.track('App Install Prompt Shown');
					let outcome = await self.state.installPrompt.userChoice;

					if (outcome.outcome === 'accepted') {
						self.props.amplitude.getInstance().logEvent('App Installed');
						self.props.mixpanel.track('App Installed');
						await self.setState({ installPrompt: '' });
					} else {
						self.props.amplitude.getInstance().logEvent('App Install Rejected');
						self.props.mixpanel.track('App Install Rejected');
					}
				} catch (err) {
					console.log(err);
				}
			}
		});
	}

	async handleUserAttachment(file) {
		if (localStorage.getItem('userLocked')) {
			console.log('ignoring message from locked user ' + this.state.userId);
			return;
		}
		const self = this;
		const uniqueId = uuidv4();
		const videoMIME = 'video/*';
		const pdfMIME = 'application/pdf';
		const docMIME = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
		if (file.type.match([videoMIME, pdfMIME, docMIME].join('|'))) {
			const url = process.env.REACT_APP_BACKEND_URL + '/uploadCompressed';
			await self.renderSpinnerForImage(166, 215);
			const res = await self.uploadFile({ file, uniqueId }, url);
			let thumbnailImage;
			if (file.type.match(pdfMIME)) {
				thumbnailImage = '/pdf_thumbnail.png';
			} else if (file.type.match(docMIME)) {
				thumbnailImage = '/docx_thumbnail.png';
			}
			const mediaData = {
				uniqueId: uniqueId,
				file: file,
				imageUrl: file.type.match(videoMIME) ? null : res.data.imageUrl,
				videoUrl: file.type.match(videoMIME) ? res.data.imageUrl : null,
				thumbnailUrl: thumbnailImage ? process.env.REACT_APP_PUBLIC_URL + thumbnailImage : null,
				width: 100,
				height: 100
			};
			self.handleUserMessage({}, mediaData);
			return;
		}
		const maxWidth = 800;
		EXIF.getData(file, async function () {
			var orientation = EXIF.getTag(this, 'Orientation');

			const reader = new FileReader();
			reader.readAsDataURL(file);

			reader.onload = (event) => {
				const img = new Image();
				img.src = event.target.result;

				img.onload = async () => {
					if (img.width <= maxWidth) {
						await self.renderSpinnerForImage(img.width, img.height);
						const url = process.env.REACT_APP_BACKEND_URL + '/uploadCompressed';
						const res = await self.uploadFile({ file, uniqueId }, url);
						const imageData = {
							imageUrl: res.data.imageUrl,
							width: img.width,
							height: img.height
						};
						self.handleUserMessage({}, imageData);
					} else {
						const elem = document.createElement('canvas');
						const scaleFactor = maxWidth / img.width;
						elem.width = maxWidth;
						elem.height = img.height * scaleFactor;
						await self.renderSpinnerForImage(elem.width, elem.height);
						const height = elem.height;
						const width = elem.width;
						const ctx = elem.getContext('2d');
						if (orientation) {
							if (orientation > 4) {
								elem.width = height;
								elem.height = width;
							}
							switch (orientation) {
								case 2:
									ctx.translate(width, 0);
									ctx.scale(-1, 1);
									break;
								case 3:
									ctx.translate(width, height);
									ctx.rotate(Math.PI);
									break;
								case 4:
									ctx.translate(0, height);
									ctx.scale(1, -1);
									break;
								case 5:
									ctx.rotate(0.5 * Math.PI);
									ctx.scale(1, -1);
									break;
								case 6:
									ctx.rotate(0.5 * Math.PI);
									ctx.translate(0, -height);
									break;
								case 7:
									ctx.rotate(0.5 * Math.PI);
									ctx.translate(width, -height);
									ctx.scale(-1, 1);
									break;
								case 8:
									ctx.rotate(-0.5 * Math.PI);
									ctx.translate(-width, 0);
									break;
							}
						}
						ctx.drawImage(img, 0, 0, maxWidth, img.height * scaleFactor);

						ctx.canvas.toBlob(
							async (blob) => {
								const compressedFile = new File([blob], file.name, {
									type: 'image/jpg',
									lastModified: Date.now()
								});

								let url = process.env.REACT_APP_BACKEND_URL + '/uploadCompressed';
								const res = await self.uploadFile({ file: compressedFile, uniqueId }, url);
								const imageData = {
									imageUrl: res.data.imageUrl,
									width: maxWidth,
									height: elem.height
								};
								self.handleUserMessage({}, imageData);
								await self.sleep(5000);
								url = process.env.REACT_APP_BACKEND_URL + '/uploadUncompressed';
								self.uploadFile({ file, uniqueId }, url);
							},
							'image/jpg',
							1
						);
					}
				};

				reader.onerror = (error) => console.log(error);
			};
		});
	}

	async uploadFile(file, url) {
		const data = new FormData();
		data.append('file', file.file);
		data.append('uniqueId', file.uniqueId);
		data.append('userId', this.state.userId);
		const res = await axios.post(url, data);

		return res;
	}

	async handleTabChange(currentTab) {
		await this.setState({ currentTab: currentTab });

		if (this.state.currentTab === 0) {
			this.lastDiv.scrollIntoView({ behavior: 'smooth' });
		}
	}

	async handleAppliedJobs(currentJobs) {
		this.setState({ currentJobs: currentJobs });
	}

	async handleProfile(profile) {
		this.setState({ profile: profile });
	}

	async handleVideos(videos) {
		this.setState({ videos: videos });
	}

	async handleFeedbackModal(feedbackModalStatus) {
		this.setState({ feedbackModalStatus: feedbackModalStatus });
	}

	async handleCloseModal() {
		this.setState({ feedbackModalStatus: false });
	}

	async setRef(ref) {
		this.lastDiv = ref;
	}

	async handleNotificationResponse(selection) {
		const url = process.env.REACT_APP_BACKEND_URL + '/updateScheduledJob';
		const data = new FormData();
		data.append('action', selection.response);
		data.append('jobId', selection.jobId);
		data.append('userId', this.state.userId);
		await axios.post(url, data);
		await this.getJobs();
	}

	async getJobs() {
		const userId = await localStorage.getItem('userId');

		const response = await fetch(
			`${process.env.REACT_APP_BACKEND_URL}/getJobs?userId=${encodeURIComponent(userId)}`
		);

		let userJobs = await response.json();

		await this.setState({
			currentJobs: userJobs.jobs
		});
	}

	async getProfile() {
		const userId = await localStorage.getItem('userId');
		const userProfile = profileService.getProfile(userId);
		await this.setState({ profile: userProfile });
	}

	async getVideos() {
		const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/getVideos`);
		let videoList = await response.json();
		await this.setState({ videos: videoList.videoList });
	}

	async processMessageObjForNewJobCard(messageObj) {
		messageObj.isNewJobCard = false;
		let message = messageObj.message;

		if (!message || message.indexOf('{{newJobCard}}') === -1) {
			return messageObj;
		}

		message = message.replace(/{{newJobCard}}/g, '');
		message = message.replace(/\n/, '');
		messageObj.isNewJobCard = true;
		messageObj.message = message;

		return messageObj;
	}

	async processMessageObjForApb(messageObj) {
		let message = messageObj.message;

		let mapsUrl = 'https://www.google.com/maps/search/?api=1&query=';

		if (!message || message.indexOf('{{APBGoogleLoc}}') === -1) {
			return messageObj;
		}

		mapsUrl = mapsUrl + messageObj.apb.lat + ',' + messageObj.apb.long;

		message = message.replace(/{{APBGoogleLoc}}/g, mapsUrl);
		messageObj.isNewJobCard = true;
		messageObj.message = message;

		return messageObj;
	}

	render() {
		if (this.state.currentTab === 0) {
			if (
				window.location.href.includes(process.env.REACT_APP_PUBLIC_URL + '/jobs') ||
				window.location.href.includes(process.env.REACT_APP_PUBLIC_URL + '/profile')
			) {
				window.location = process.env.REACT_APP_PUBLIC_URL;
			}

			return (
				<div className="wrapper">
					<div>
						<TopMenu amplitude={this.props.amplitude} mixpanel={this.props.mixpanel} />
						<Suspense fallback={renderLoader()}>
							<div>
								<OfflineNotification />
							</div>
							<Menu
								amplitude={this.props.amplitude}
								mixpanel={this.props.mixpanel}
								tabChange={this.handleTabChange}
								appliedJobs={this.handleAppliedJobs}
								profile={this.handleProfile}
								videos={this.handleVideos}
								currentTab={this.state.currentTab}
								language={this.state.language}
							/>
						</Suspense>
					</div>
					<Suspense fallback={renderLoader()}>
						{/* <Spam
							amplitude={this.props.amplitude}
							userMessage={this.handleUserMessage}
							mixpanel={this.props.mixpanel}
							language={this.state.language}
						>
						</Spam>
						*/}

						<div className="message-list" id="message-list">
							<MessageList
								amplitude={this.props.amplitude}
								mixpanel={this.props.mixpanel}
								messages={this.state.messages}
								userMessage={this.handleUserMessage}
								setRef={this.setRef}
								newBotMessage={this.state.newBotMessage}
								newUserMessage={this.state.newUserMessage}
							/>
						</div>
						<SendMessageForm
							amplitude={this.props.amplitude}
							mixpanel={this.props.mixpanel}
							userMessage={this.handleUserMessage}
							userAttachment={this.handleUserAttachment}
							lastDiv={this.lastDiv}
							messages={this.state.messages}
							language={this.state.language}
						/>
						{/* <OtpPopup amplitude={this.props.amplitude} mixpanel={this.props.mixpanel}/> */}
						{this.state.feedbackModalStatus === true ? (
							<Feedback
								userId={this.state.userId}
								handleModal={this.handleFeedbackModal}
								language={this.state.language}
							/>
						) : null}
					</Suspense>
				</div>
			);
		} else if (this.state.currentTab === 1) {
			if (window.location.href.includes(process.env.REACT_APP_PUBLIC_URL + '/profile')) {
				window.location = process.env.REACT_APP_PUBLIC_URL;
			}
			return (
				<div className="wrapper-jobs-profile">
					{/* <div className = 'container-section'> */}
					<div>
						<TopMenu />
						<Suspense fallback={renderLoader()}>
							<Menu
								mixpanel={this.props.mixpanel}
								amplitude={this.props.amplitude}
								tabChange={this.handleTabChange}
								appliedJobs={this.handleAppliedJobs}
								profile={this.handleProfile}
								videos={this.handleVideos}
								currentTab={this.state.currentTab}
								language={this.state.language}
							/>
						</Suspense>
					</div>
					<Suspense fallback={renderLoader()}>
						<div>
							<MyJobs
								mixpanel={this.props.mixpanel}
								amplitude={this.props.amplitude}
								currentJobs={this.state.currentJobs}
								profile={this.state.profile}
								userNotificationResponse={this.handleNotificationResponse}
								language={this.state.language}
							/>
						</div>
					</Suspense>
					{/* </div> */}
				</div>
			);
		} else if (this.state.currentTab === 2) {
			if (
				window.location.href.includes(process.env.REACT_APP_PUBLIC_URL + '/profile') &&
				!localStorage.getItem('userId')
			) {
				window.location = process.env.REACT_APP_PUBLIC_URL;
			}
			if (window.location.href.includes(process.env.REACT_APP_PUBLIC_URL + '/jobs')) {
				window.location = process.env.REACT_APP_PUBLIC_URL;
			}

			return (
				<div className="wrapper-jobs-profile">
					{/* <div className = 'container-section'> */}
					<div>
						<TopMenu />
						<Suspense fallback={renderLoader()}>
							<Menu
								mixpanel={this.props.mixpanel}
								amplitude={this.props.amplitude}
								tabChange={this.handleTabChange}
								appliedJobs={this.handleAppliedJobs}
								profile={this.handleProfile}
								videos={this.handleVideos}
								currentTab={this.state.currentTab}
								language={this.state.language}
							/>
						</Suspense>
					</div>
					<Suspense fallback={renderLoader()}>
						<div>
							<NewMyProfile
								mixpanel={this.props.mixpanel}
								amplitude={this.props.amplitude}
								currentJobs={this.state.currentJobs}
								profile={this.state.profile}
								language={this.state.language}
								uploadFile={this.uploadFile}
								openUploadModal={
									window.location.href.includes(process.env.REACT_APP_PUBLIC_URL + '/profile') ? (
										true
									) : (
											false
										)
								}
							/>
						</div>
					</Suspense>
					{/* </div> */}
				</div>
			);
		} else if (this.state.currentTab === 3) {
			if (window.location.href.includes(process.env.REACT_APP_PUBLIC_URL + '/profile')) {
				window.location = process.env.REACT_APP_PUBLIC_URL;
			}
			if (window.location.href.includes(process.env.REACT_APP_PUBLIC_URL + '/jobs')) {
				window.location = process.env.REACT_APP_PUBLIC_URL;
			}
			return (
				<div className="wrapper-jobs-profile">
					{/* <div className = 'container-section'> */}
					<div>
						<TopMenu />
						<Suspense fallback={renderLoader()}>
							<Menu
								mixpanel={this.props.mixpanel}
								amplitude={this.props.amplitude}
								tabChange={this.handleTabChange}
								appliedJobs={this.handleAppliedJobs}
								profile={this.handleProfile}
								videos={this.handleVideos}
								currentTab={this.state.currentTab}
								language={this.state.language}
							/>
						</Suspense>
					</div>
					<Suspense fallback={renderLoader()}>
						<div>
							<Videos
								mixpanel={this.props.mixpanel}
								amplitude={this.props.amplitude}
								videos={this.state.videos}
								profile={this.state.profile}
								userNotificationResponse={this.handleNotificationResponse}
								language={this.state.language}
							/>
						</div>
					</Suspense>
					{/* </div> */}
				</div>
			);
		}
	}
}

const renderLoader = () => <p />;

class App extends React.Component {
	render() {
		return (
			<Router>
				<Switch>
					<Route path="/profile">
						<JobFinder mixpanel={this.props.mixpanel} amplitude={this.props.amplitude} currentTab={2} />
					</Route>
					<Route path="/jobs">
						<JobFinder mixpanel={this.props.mixpanel} amplitude={this.props.amplitude} currentTab={1} />
					</Route>
					<Route
						path="/referral/:userId"
						render={({ match }) => (
							<Referral match={match} mixpanel={this.props.mixpanel} amplitude={this.props.amplitude} />
						)}
					/>
					<Route path="/documents">
						<DocUpload amplitude={this.props.amplitude} />
					</Route>
                    <Route path="/location">
						<CaptureLocation amplitude={this.props.amplitude} />
					</Route>
					<Route path="/">
						<JobFinder mixpanel={this.props.mixpanel} amplitude={this.props.amplitude} currentTab={0} />
					</Route>
				</Switch>
			</Router>
		);
	}
}

export default App;
