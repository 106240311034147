export const getProfile = async (userId) => {
    const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/getProfile?userId=${encodeURIComponent(userId)}`
    );
    const userProfile = await response.json();
    if (!userProfile.profile || !userProfile.profile.length) {
        return {};
    } else {
        return userProfile.profile[0];
    }
}

export const getUserChatHistory = (userId, phone, verificationToken, locallyStoredMsgCount) => {
    let url = `${process.env.REACT_APP_BACKEND_URL}/getUserChatHistory?userId=${userId}&phone=${phone}&verificationToken=${verificationToken}&locallyStoredMsgCount=${locallyStoredMsgCount}`;
    return fetch(url).then(res => res.json());
}

export const getUserForLead = (mitraId, leadId) => {
    const url = `${process.env.REACT_APP_BACKEND_URL}/user?leadId=${leadId}&mitraId=${mitraId}`;
    return fetch(url).then(res => res.json());
}