import React from 'react';
import axios from 'axios';
import {Button} from '@rmwc/button';
import PlacesAutocomplete, {geocodeByAddress, getLatLng} from 'react-places-autocomplete';
import '../LocationSearch.css';
import MapMyIndiaPlacesAutoSuggest from "./MapMyIndiaPlacesAutoSuggest";
import LocalizedStrings from 'react-localization';

import uuidv4 from 'uuid/v4';

let strings = new LocalizedStrings({
    "en-IN": {
        typeLocation: "Type Location, eg. Delhi..",
        hi: "Hi",
        live: "We will show you jobs in your preferred area. Please share the location where you want to work.",
        preferredAreas: "We will show you jobs in your preferred areas. Please share your preferred area.",
        firstPref: "Please enter your first preference as work location",
        secondPref: "Please enter your second preference as work location",
        thirdPref: "Please enter your third preference as work location",
        loading: "Loading...",
        validatingUser: "Validating User..",
        returnAgree: "I WANT WHATSAPP ALERTS",
        returnDisAgree: "DON'T WANT WHATSAPP ALERTS",
        locationShared: "Location shared | Click to Send >>>",
    },
    "hi-IN": {
        typeLocation: "शहर, टाइप करें| जैसे: दिल्ली |",
        hi: "नमस्ते",
        live: "हम आपके पसंदीदा शहर में नौकरियां दिखाएंगे। कृपया अपने काम करने के स्थान को हमसे साझा करें।",
        preferredAreas: "हम आपको अपने पसंदीदा क्षेत्रों में नौकरी दिखाएंगे। कृपया अपना पसंदीदा क्षेत्र साझा करें।",
        firstPref: "कृपया अपना पहला पसंदीदा क्षेत्र कार्य स्थान के रूप में डाले",
        secondPref: "कृपया अपना दूसरा पसंदीदा क्षेत्र कार्य स्थान के रूप में डाले",
        thirdPref: "कृपया अपना तीसरा पसंदीदा क्षेत्र कार्य स्थान के रूप में डाले",
        loading: "लोड हो रहा है...",
        validatingUser: "मान्य उपयोगकर्ता..",
        returnAgree: "मुझे वव्हाट्सएप अलर्ट चाहिए",
        returnDisAgree: "मैं व्हाट्सऐप अलर्ट नहीं चाहता",
        locationShared: "लोकेशन शेयर हो गया हैं | भेजने के लिए क्लिक करें >>>",
    },
    "kd-IN": {
        typeLocation: "ಸ್ಥಳವನ್ನು ಟೈಪ್ ಮಾಡಿ, ಉದಾ. ಬೆಂಗಳೂರು..",
        hi: "ನಮಸ್ತೆ",
        live: "ನಿಮ್ಮ ಆದ್ಯತೆಯ ಪ್ರದೇಶದಲ್ಲಿ ನಾವು ನಿಮಗೆ ಉದ್ಯೋಗಗಳನ್ನು ತೋರಿಸುತ್ತೇವೆ. ನೀವು ಕೆಲಸ ಮಾಡಲು ಬಯಸುವ ಸ್ಥಳವನ್ನು ದಯವಿಟ್ಟು ಹಂಚಿಕೊಳ್ಳಿ",
        preferredAreas: "ನಿಮ್ಮ ಆದ್ಯತೆಯ ಪ್ರದೇಶಗಳಲ್ಲಿ ನಾವು ನಿಮಗೆ ಉದ್ಯೋಗಗಳನ್ನು ತೋರಿಸುತ್ತೇವೆ. ದಯವಿಟ್ಟು ನಿಮ್ಮ ಆದ್ಯತೆಯ ಪ್ರದೇಶವನ್ನು ಹಂಚಿಕೊಳ್ಳಿ",
        firstPref: "ದಯವಿಟ್ಟು ನಿಮ್ಮ ಮೊದಲ ಆದ್ಯತೆಯನ್ನು ಕೆಲಸದ ಸ್ಥಳವಾಗಿ ನಮೂದಿಸಿ",
        secondPref: "ದಯವಿಟ್ಟು ನಿಮ್ಮ ಎರಡನೇ ಆದ್ಯತೆಯನ್ನು ಕೆಲಸದ ಸ್ಥಳವಾಗಿ ನಮೂದಿಸಿ",
        thirdPref: "ದಯವಿಟ್ಟು ನಿಮ್ಮ ಮೂರನೇ ಆದ್ಯತೆಯನ್ನು ಕೆಲಸದ ಸ್ಥಳವಾಗಿ ನಮೂದಿಸಿ",
        loading: "ಲೋಡ್ ಆಗುತ್ತಿದೆ...",
        validatingUser: "ಬಳಕೆದಾರರನ್ನು ಮೌಲ್ಯೀಕರಿಸಲಾಗುತ್ತಿದೆ..",
        returnAgree: "ನಾನು ವಾಟ್ಸಾಪ್ ಅಲರ್ಟ್‌ಗಳನ್ನು ಬಯಸುತ್ತೇನೆ",
        returnDisAgree: "ನಾನು ವಾಟ್ಸಾಪ್ ಅಲರ್ಟ್‌ಗಳನ್ನು ಬಯಸುವುದಿಲ್ಲ",
        locationShared: "ಸ್ಥಳ ನೀಡಲಾಗಿದೆ | ಕಳುಹಿಸಲು ಕ್ಲಿಕ್ ಮಾಡಿ >>>",
    }
});
const searchOptions = {
    componentRestrictions: { country: ['IN'] }
  }

class CaptureLocation extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            userId: '',
            ifValidated: false,
            name: '',
            home: '',
            firstWorkPreference: '',
            secondWorkPreference: '',
            thirdWorkPreference: '',
            gpsPopUp: true
        };
        this.sleep = this.sleep.bind(this);
        this.handleWhatsAppReturnAgree = this.handleWhatsAppReturnAgree.bind(this);
        this.handleWhatsAppReturnDisAgree = this.handleWhatsAppReturnDisAgree.bind(this);
        this.handleSelect = this.handleSelect.bind(this);
        this.getGeoLocation = this.getGeoLocation.bind(this);
        this.sendReturnEvent = this.sendReturnEvent.bind(this);
    }

    async componentDidMount() {
        const phone = new URL(window.location.href).searchParams.get('phone') || '';
        const verificationToken = new URL(window.location.href).searchParams.get('token') || '';

        let url = `${process.env.REACT_APP_BACKEND_URL}/validateUrl?
		phone=${phone}&
		verificationToken=${verificationToken}`;

        let validateUser = {};
        try {
            let validateUserResponse = await fetch(url);
            console.log('User Validation Done');
            validateUser = await validateUserResponse.json();
        } catch (error) {
            console.log('Error in validating user');
            console.log(error);
        }
    
        if (validateUser.userId) {
            strings.setLanguage(validateUser.locale ? validateUser.locale : 'en-IN');
            const userId = validateUser.userId;
            localStorage.setItem('userId', userId);
            this.props.amplitude.getInstance().setUserId(userId);
            if(validateUser.locale!='en-IN'){
            await this.setState({userId: userId, name: validateUser.name ? validateUser.name :''}); 
            }
            else{
                await this.setState({userId: userId, name: validateUser.name && validateUser.name!='firstName'? validateUser.name :'there'});
            }
            const eventProperties = {product: 'jobFinder', screen: 'locationPreference'};
            const url = process.env.REACT_APP_BACKEND_URL + '/logLocationPageEvents';
            const data = new FormData();
            data.append('userId', this.state.userId);
            data.append('eventName', 'LocationPreferencePageLoaded');
            data.append('eventProperties', JSON.stringify(eventProperties));
            // axios.post(url, data);
            if (this.state.gpsPopUp) {
                this.getGeoLocation();
            }
        }
        await this.setState({ifValidated: true});
    }

    async sleep(ms) {
        return new Promise((resolve) => setTimeout(resolve, ms));
    }

    async handleChange(type, address) {
        this.setState({[type]: address});
        await this.sleep(1000);
    }

    async handleSelect(type, suggestion) {
        const locationSelected = suggestion.suggestionValue;
        this.setState({[type]: locationSelected});
        this.props.amplitude.getInstance().logEvent('Location Selected', {
            type: type,
            locationSelected: locationSelected
        });
        const eventProperties = {product: 'jobFinder', screen: 'locationPreference', area: locationSelected};
        const urlAmp = process.env.REACT_APP_BACKEND_URL + '/logLocationPageEvents';
        const dataAmp = new FormData();
        dataAmp.append('userId', this.state.userId);
        if (type === 'home') {
            dataAmp.append('eventName', 'JobSeekerSharedHomeLocation');
        } else if (type === 'firstWorkPreference') {
            dataAmp.append('eventName', 'JobSeekerSharedFirstAreaPreference');
        } else if (type === 'secondWorkPreference') {
            dataAmp.append('eventName', 'JobSeekerSharedSecondAreaPreference');
        } else if (type === 'thirdWorkPreference') {
            dataAmp.append('eventName', 'JobSeekerSharedThirdAreaPreference');
        }
        dataAmp.append('eventProperties', JSON.stringify(eventProperties));
        axios.post(urlAmp, dataAmp);

        const url = process.env.REACT_APP_BACKEND_URL + '/locationPreferences';
        const data = new FormData();
        data.append('eLoc', suggestion.suggestion.eLoc);
        data.append('lat', suggestion.suggestion.latitude);
        data.append('lng', suggestion.suggestion.longitude);
        data.append('userId', this.state.userId);
        data.append('type', type);
        data.append('locationSelected', locationSelected);
        await axios.post(url, data);
        if (this.state.gpsPopUp) {
            this.getGeoLocation();
        }
    }

    async sendReturnEvent() {
        const eventProperties = {product: 'jobFinder', screen: 'locationPreference'};
        const url = process.env.REACT_APP_BACKEND_URL + '/logLocationPageEvents';
        const data = new FormData();
        data.append('userId', this.state.userId);
        data.append('eventName', 'JobSeekerDoneWithLocationPreference');
        data.append('eventProperties', JSON.stringify(eventProperties));
        axios.post(url, data);
    }

    async handleWhatsAppReturnAgree() {
        this.sendReturnEvent();
        const url = process.env.REACT_APP_BACKEND_URL + '/optInUserForWhatsAppAlerts';
        const data = new FormData();
        const sendSharedLocation = strings.locationShared;
        data.append('userId', this.state.userId);
        await axios.post(url, data);
        window.location = 'https://api.whatsapp.com/send?phone=918050205205&text='+sendSharedLocation;
    }

    async handleWhatsAppReturnDisAgree() {
        this.sendReturnEvent();
        const sendSharedLocation = strings.locationShared;
        window.location = 'https://api.whatsapp.com/send?phone=918050205205&text='+sendSharedLocation;
    }

    async getGeoLocation() {
        const self = this;
        if (navigator.geolocation) {
            // geolocation is available
            const options = {enableHighAccuracy: false, maximumAge: 100, timeout: 60000};
            navigator.geolocation.getCurrentPosition(
                async function (position) {
                    const urlAmp = process.env.REACT_APP_BACKEND_URL + '/logLocationPageEvents';
                    const eventProperties = {product: 'jobFinder', screen: 'locationPreference'};
                    const dataAmp = new FormData();
                    dataAmp.append('userId', self.state.userId);
                    dataAmp.append('eventName', 'JobSeekerSharedGPSLocation');
                    dataAmp.append('eventProperties', JSON.stringify(eventProperties));
                    axios.post(urlAmp, dataAmp);

                    const url = process.env.REACT_APP_BACKEND_URL + '/locationPreferences';
                    const data = new FormData();
                    data.append('lat', position.coords.latitude);
                    data.append('lng', position.coords.longitude);
                    data.append('userId', self.state.userId);
                    data.append('type', 'GPS');
                    data.append('locationSelected', JSON.stringify(position.coords));
                    await axios.post(url, data);
                    self.setState({gpsPopUp: false});
                    console.log(position);
                },
                function (err) {
                    console.error(err);
                },
                options
            );
        } else {
            // geolocation IS NOT available
            console.log('Geolocation not supported by this device');
        }
    }

    render() {
        if (this.state.ifValidated) {
            if (!this.state.userId) {
                window.location = 'https://api.whatsapp.com/send?phone=918050205205';
            } else {
                return (
                    <div>
                        <div className="mitralogo">
                            <img alt="" title="" height="auto" src="http://uploads-a.vahan.co/3l3QGE-mitra_logo" width="90" ></img>
                        </div>
                        <div className="captureLocationPage">
                            <div
                                className="preference-heading-title">{strings.hi} {this.state.name=='firstName'?'':this.state.name}, <br/>{strings.live}</div>
                            <div style={{width: '100%'}}>
                                <MapMyIndiaPlacesAutoSuggest
                                    placeholder={strings.typeLocation}
                                    onSuggestionSelected={(event, suggestion) =>
                                        this.handleSelect('firstWorkPreference', suggestion)
                                    }
                                    renderSuggestion={suggestion => (
                                        <div className={'suggestion-item'} style={{'cursor': 'pointer'}}>
                                            <span>{suggestion.placeName}, {suggestion.placeAddress}</span>
                                        </div>
                                    )
                                    }
                                />
                            </div>
                            <br/><br/>
                            <br/><br/>
                            {/* <div style={{marginBottom: '2rem'}}
                                 className="preference-heading-title">{strings.preferredAreas}</div>
                            <div className="preference-heading-title">{strings.firstPref}</div>
                            <div style={{width: '100%'}}>
                                <MapMyIndiaPlacesAutoSuggest
                                    placeholder={strings.typeLocation}
                                    onSuggestionSelected={(event, suggestion) =>
                                        this.handleSelect('firstWorkPreference', suggestion)}
                                    renderSuggestion={suggestion => (
                                        <div className={'suggestion-item'} style={{'cursor': 'pointer'}}>
                                            <span>{suggestion.placeName}, {suggestion.placeAddress}</span>
                                        </div>
                                    )
                                    }
                                />
                            </div> */}
                            {/* <div className="preference-heading-title">{strings.secondPref}</div>
                            <div style={{width: '100%'}}>
                                <MapMyIndiaPlacesAutoSuggest
                                    placeholder={strings.typeLocation}
                                    onSuggestionSelected={(event, suggestion) =>
                                        this.handleSelect('secondWorkPreference', suggestion)
                                    }
                                    renderSuggestion={suggestion => (
                                        <div className={'suggestion-item'} style={{'cursor': 'pointer'}}>
                                            <span>{suggestion.placeName}, {suggestion.placeAddress}</span>
                                        </div>
                                    )
                                    }
                                />
                            </div>
                            <div className="preference-heading-title">{strings.thirdPref}</div>
                            <div style={{width: '100%'}}>
                                <MapMyIndiaPlacesAutoSuggest
                                    placeholder={strings.typeLocation}
                                    onSuggestionSelected={(event, suggestion) =>
                                        this.handleSelect('thirdWorkPreference', suggestion)
                                    }
                                    renderSuggestion={suggestion => (
                                        <div className={'suggestion-item'} style={{'cursor': 'pointer'}}>
                                            <span>{suggestion.placeName}, {suggestion.placeAddress}</span>
                                        </div>
                                    )
                                    }
                                />
                            </div> */}
                        </div>
                        <Button raised onClick={this.handleWhatsAppReturnAgree}
                                style={{
                                    display: 'table', margin: 'auto', background: '#4aee25',
                                    color: '#000000', padding: '1rem 2rem', fontSize: '1rem',
                                    width: '62%',borderRadius:'10px',
                                }}>
                            {strings.returnAgree}
                        </Button>
                        <Button raised onClick={this.handleWhatsAppReturnDisAgree}
                                style={{
                                    display: 'table',
                                    margin: 'auto',
                                    background: '#eebb25',
                                    color: '#000000',
                                    padding: '1rem 2rem',
                                    fontSize: '1rem',
                                    marginTop: '1rem',
                                    borderRadius:'10px',
                                    width: '62%'
                                }}>
                            {strings.returnDisAgree}
                        </Button>
                    </div>
                )
            }
        } else {
            return (
                <div>
                    {strings.validatingUser}
                </div>
            )
        }
    }
}

export default CaptureLocation;
