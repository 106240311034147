import React from 'react';
import {
	TopAppBar,
	TopAppBarRow,
	TopAppBarSection,
	TopAppBarTitle,
	TopAppBarFixedAdjust,
	TopAppBarActionItem
} from '@rmwc/top-app-bar';
import { MdArrowBack } from 'react-icons/md';
import '../App.css';

import '@material/top-app-bar/dist/mdc.top-app-bar.css';

class TopMenu extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
		this.openReferralPage = this.openReferralPage.bind(this);
	}

	async openReferralPage() {
		this.props.amplitude.getInstance().logEvent('Rupee Icon clicked');
		//this.props.mixpanel.track('Rupee Icon clicked');
		const userId = await localStorage.getItem('userId');
		window.location = `${process.env.REACT_APP_PUBLIC_URL}/referral/${encodeURIComponent(userId)}`;
	}

	render() {
		return (
			<div>
				<TopAppBar>
					<TopAppBarRow>
						{this.props.name || (this.props.job && this.props.job.company && this.props.job.salary) ? (
							<TopAppBarSection>

								<div className="arrow-icon-wrapper">
									{this.props.phoneNumber ? null : (
										<a href="/">
											<MdArrowBack className="back-arrow" /> </a>)}
									<a href="/">
										<img alt="home"
											src={process.env.REACT_APP_PUBLIC_URL + '/icon_min.jpg'} />
									</a>
								</div>
								<div className="doc-header-camera-wrapper">
									<p className="doc-header">
										{this.props.phoneNumber ? `Upload IDs for ${this.props.name}:${this.props.phoneNumber}` : (
											`${this.props.name}, Upload IDs to apply for job`)}
										{this.props.job ? (` at ${this.props.job.company} salary ${this.props.job.salary}`) : null}
									</p>
									<img alt="camera"
										className="camera-icon"
										src={process.env.REACT_APP_PUBLIC_URL + '/camera_icon.png'}
									/>
								</div>
							</TopAppBarSection>
						) : (
								<div className="topmenu-wrapper">
									<TopAppBarSection>
										<img alt="" src={process.env.REACT_APP_PUBLIC_URL + '/icon_min.jpg'} />
										<TopAppBarTitle>Mitra</TopAppBarTitle>
									</TopAppBarSection>

									<TopAppBarSection alignEnd>
										{/* <TopAppBarActionItem className='topbar-action-icon'
											icon={process.env.REACT_APP_PUBLIC_URL + "/referral_icon.svg"}
											onClick={this.openReferralPage}
										>
										</TopAppBarActionItem> */}
									</TopAppBarSection>
								</div>
							)}
					</TopAppBarRow>
				</TopAppBar>
				<TopAppBarFixedAdjust />
			</div>
		);
	}
}

export default TopMenu;
